export default {
  props: {
    margin: {
      type: String,
      default: "20px 10px 10px 0"
    },
    background: {
      type: String
    },
    fontsize: {
      type: String,
      default: "14px"
    },
    title: {
      type: String
    },
    show: {
      type: Boolean
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "400px"
    },
    height: {
      type: String,
      default: "400px"
    },
    paddingleft: {
      type: String,
      default: "15px"
    },
    paddingright: {
      type: String,
      default: "12px"
    },
    borderradius: {
      type: String,
      default: "4"
    }
  },
  created: function created() {},
  methods: {
    close: function close() {
      this.$emit("close");
    }
  }
};