import "core-js/modules/es.string.fontsize.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.show ? _c('div', {
    staticClass: "dialog-wrap",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    staticClass: "dialog",
    style: {
      width: _vm.width,
      height: _vm.height,
      borderRadius: _vm.borderradius
    }
  }, [!_vm.hideClose ? _c('div', {
    staticClass: "dialog-close link-color",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "iconfont icon-close"
  })]) : _vm._e(), _c('div', {
    staticClass: "dialog-title v2-g-flex-row",
    style: {
      fontSize: _vm.fontsize,
      background: _vm.background
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.title || ""))]), _vm._t("title")], 2), _c('div', {
    staticClass: "dialog-content",
    style: {
      paddingLeft: _vm.paddingleft,
      paddingRight: _vm.paddingright
    },
    attrs: {
      "name": "dialog-content"
    }
  }, [_vm._t("default")], 2), _c('div', {
    staticClass: "dialog-btn",
    style: {
      margin: _vm.margin
    }
  }, [_vm._t("footer")], 2)])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };