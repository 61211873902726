<template>
    <div class="electronicInvoice-centent">
        <div class="electronicInvoice-title content-right-wrapper">
            <div>
                <div class="right-title" @click="isShowDetails = false">发票中心</div>
                <span v-if="isShowDetails" @click="isShowDetails = false">{{ act_tab === 0 ? "已开票订单" : "未开票订单" }}</span>
                <span v-if="isShowDetails">/发票详情</span>
            </div>
            <div class="rulers" @click="isShowRuler = true" v-if="isShowDetails">
                <img
                    src="https://obs.pujian.com/frontend/app/user/wh.png" />
                <span>开票规则</span>
            </div>
        </div>
        <div v-if="!isShowDetails">
            <div class="electronicInvoice-tabs">
                <div class="tabs-box">
                    <span :class="[act_tab === 0 ? 'act_tab' : '']" @click="checkTab(0)">已开票订单</span>
                    <span :class="[act_tab === 1 ? 'act_tab' : '']" @click="checkTab(1)">未开票订单</span>
                </div>
                <div class="rulers" @click="isShowRuler = true">
                    <img src="https://obs.pujian.com/frontend/app/user/wh.png" />
                    <span>开票规则</span>
                </div>
            </div>
            <div class="switchTab" v-if="chainData && chainData.isChain == 1">
                <div class="switchBox">
                    <div class="switchBox-item act-item" :style="{'left':act_index === 1 ? '50%' : '0'}">{{act_str}}</div>
                    <div :class="['switchBox-item']" v-for="(str,index) in modeTabList" :key="index" @click="tabChange(str,index)">{{str}}</div>
                </div>
            </div>
            <div class="electronicInvoice-search" style="height: auto" v-if="act_index === 1">
                <div class="search-item">
                  <span>业务员</span>
                  <el-select v-model="salesmanCurrent" collapse-tags multiple placeholder="请选择" style="width: 300px;" @change="staffIdsChange">
                    <el-option
                      v-for="item in chainData.staffIds"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </div>
                <div class="search-item">
                  <span>门店</span>
                  <el-select v-model="storeCurrent" collapse-tags multiple placeholder="请选择" style="width: 300px;" @change="companyIdsChange">
                    <el-option
                      v-for="item in chainData.companyIds"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </div>
            </div>
            <div class="electronicInvoice-search">
                <div class="search-item">
                    <span>订单搜索</span>
                    <b-input placeholder="请输入订单编号" v-model="search_val" icon="search" icon-right="close-circle"
                        icon-right-clickable @keyup.enter.native="getInvoiceList" @icon-right-click="clearIconClick">
                    </b-input>
                </div>
                <div class="search-item">
                    <span>时间范围</span>
                    <date-picker v-model="time" type="month" value-type="format" format="YYYY-MM" :disabled-date="disabledDate" placeholder="请选择日期"
                        @input="handleSelect" @clear="handleClear" range>
                    </date-picker>
                </div>
                <template v-if="act_tab === 0">
                  <div class="btn" @click="getShareUuid('share')">发票分享</div>
                  <div class="btn" @click="getShareUuid('download')">发票下载</div>
                  <div class="btn" @click="getShareUuid('email')">发送邮箱</div>
                </template>
            </div>
            <p class="ele-des">仅支持电子数电发票的分享/下载</p>
            <div class="electronicInvoice-list">
                <div class="empty" v-if="infoList.length === 0">
                    <div class="empty-list">
                        <img src="./images/pic_empty.png" alt="" />
                        <span>暂无数据</span>
                    </div>
                </div>
                <div class="ele-item" v-if="act_tab === 0 && infoList.length > 0">
                    <div class="ele-item-par">
                        <div class="le">
                            <b-checkbox v-if="act_tab === 0" v-model='allChecked' type="is-info" @input="allOrderChange">全选&emsp;&emsp;合计</b-checkbox>
                            <span class="ele-item-type ttl">订单：{{ checkOrder }}笔</span>
                            <span class="ele-item-type ttl">金额：￥{{ checkMoney.toFixed(2) }}</span>
                            <span class="ele-item-type ttl">发票：{{ checkInvoice }}张</span>
                        </div>
                        <span></span>
                    </div>
                </div>
                <div class="ele-item" v-for="(item, index) in infoList" :key="index">
                    <div class="ele-item-par">
                        <div class="le">
                            <b-checkbox v-if="act_tab === 0" v-model='item.checked' type="is-info" @input="monthCheckboxChange($event,item,index)">{{ item.newMonth }}</b-checkbox>
                            <span class="ele-item-type" v-if="act_tab === 1">{{ item.newMonth }}</span>
                            <span class="ele-item-type">订单：{{ item.orderCount }}笔</span>
                            <span class="ele-item-type">金额：￥{{ item.orderAmountCount }}</span>
                            <span class="ele-item-type" v-if="act_tab === 0">
                              发票：{{ item.billCount }}张
                              <span class="preTxt" @click="previewInvoice(item.orderInvoiceShopVOList)">预览</span>
                            </span>
                            <span v-else></span>
                        </div>
                        <img :src="r_down" :style="{'transform': item.isShowMore ? 'rotate(-90deg)' : 'rotate(90deg)'}" @click="readMore(item.isShowMore,index)">
                    </div>
                    <div class="il-item-box" v-if="item.isShowMore">
                        <div v-for="(temp,tindex) in item.orderInvoiceShopVOList" :key="tindex">
                            <div class="ele-item-par">
                                <div class="le">
                                    <b-checkbox v-if="act_tab === 0" v-model='temp.checked' type="is-info" @input="userCheckboxChange($event,item,index,temp,tindex)">{{temp.companyName}}</b-checkbox>
                                    <span class="ele-item-type" v-if="act_tab === 1">{{temp.companyName}}</span>
                                    <span class="ele-item-type">订单：{{ temp.orderCount }}笔</span>
                                    <span class="ele-item-type">金额：￥{{ temp.orderAmountCount }}</span>
                                    <span class="ele-item-type" v-if="act_tab === 0">
                                      发票：{{ temp.billCount }}张
                                      <span class="preTxt" @click="previewInvoice(item.orderInvoiceShopVOList)">预览</span>
                                    </span>
                                    <span v-else></span>
                                </div>
                                <div></div>
                            </div>
                            <div
                              class="electronicInvoice-list-item"
                              @click="toDeatils(child)"
                              v-for="(child, cindex) in temp.orderInvoiceListVoList"
                              :key="cindex">
                                <p>
                                    <span class="oderNum">订单时间：{{ child.orderTime }} </span>
                                </p>
                                <p>
                                    <span class="oderNum">{{ child.erpOrderId }}</span>
                                    <span class="orderMoney">￥{{child.orderAmount}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<!--            <div class="pagination" style="width: 940px;margin-top:40px; justify-content: flex-end; margin-left: 0px" v-if="infoList.length !== 0">-->
<!--               <Pager :pageInfo="pageInfo" class="is-centered" @getdata="handleChange"></Pager>-->
<!--            </div>-->
        </div>
        <!--        发票详情-->
        <electronicInvoiceDetails :detailData="detailData" @backInvoice="backInvoice" v-else></electronicInvoiceDetails>
        <!--发送邮箱-->
        <Dialog :show="isShowEmail" title="发送邮箱" width="500px" height="400px" @close="isShowEmail = false">
            <div class="message-dialog" style="width: 100%">
                <h4 class="groupTitle">
                    <span>请填写接收发票的邮箱地址</span>
                </h4>
                <div class="rulerContent">
                    <div class="i_box">
                        <input v-model="customerMail" placeholder="输入邮箱地址" />
                    </div>
                </div>
                <div class="email_btns">
                    <div class="email_btns_item e_c" @click="isShowEmail = false">
                        取消
                    </div>
                    <div class="email_btns_item e_s" @click="subEmailShare">
                        发送到邮箱
                    </div>
                </div>
            </div>
        </Dialog>
        <!--错误提示-->
        <Dialog :show="isShowMsg" title="提示" width="500px" height="240px" @close="isShowMsg = false">
            <div class="message-dialog" style="width: 100%">
                <h4 class="groupTitle">
                    <span>仅支持{{ popupInvoicePrompt }}</span>
                    <br>
                    <span>分享、下载、发送邮箱</span>
                </h4>
                <div class="email_btns">
                    <div class="email_btns_item e_c" @click="knowBtn">
                        知道了
                    </div>
                </div>
            </div>
        </Dialog>
        <!--        发票规则-->
        <Dialog :show="isShowRuler" title="开票规则" width="600px" height="627px" @close="isShowRuler=false">
            <div class="rulerContent">
                <p class="ruler-title">一、电子发票开票方式</p>
                <p class="name">1、【按单开具】</p>
                <p class="name">(1)销售单：订单配送完成后，48小时内按单自动开具蓝字电子发票。</p>
                <p class="name">(2)退货单：普健收到退货入库后，48小时内按单自动开具红字电子发票。</p>
                <p class="name pt">2、【周期开票】</p>
                <p class="name">(1)开票日期：可联系业务员设定，不做要求的统一为每月30号。</p>
                <p class="name">注：若设定日期>当月最后一天日期的，实际开票日期为当月最后一天。</p>
                <p class="name">如遇税务系统问题，可能自动顺延至下一工作日。</p>
                <p class="name">(2)开票内容：开票日期汇总 开票日前所有未开发票单据并自动开具电子发票。</p>
                <p class="name">销售单汇总开具蓝字电子发票，退货汇总开具红字电子发票。</p>
                <p class="ruler-title pt">二、开票金额</p>
                <p class="name">发票开具金额为您实际应支付金额，不包括在线支付享受的支付优惠金额。</p>
                <p class="ruler-title pt">三、发票购买单位</p>
                <p class="name">为商品购买单位名称，如有总分支机构等原因需要汇总开具的，请提供相关证明材料。</p>
                <p class="ruler-title pt">四、发票补开换开</p>
                <p class="name">由于开票信息有误等原因需要补开、换开发票的，请联系业务员。</p>
                <p class="ruler-title pt">五、如何查看发票</p>
                <p class="name">2个地方可以发票查看、分享、下载电子发票：</p>
                <p class="name">(1)我的--我的订单</p>
                <p class="name">(2)我的--查看发票</p>
            </div>
        </Dialog>
        <b-modal v-model="previewPop" scroll="keep" has-modal-card trap-focus :destroy-on-hide="false"
                 aria-role="dialog" aria-modal>
            <template #default="props">
                <div class="invoice-dialog">
                    <div class="urlNum">
                        {{ actUrl + 1}}/{{ previewPopData.length }}
                    </div>
                    <div class="rulerContent">
                        <b-carousel :autoplay="false" :pause-info="false" :arrow="arrow" :repeat="arrowBoth"
                                    :arrow-hover="arrowHover" :indicator="false" @change="changeUrl">
                            <b-carousel-item v-for="(carousel, index) in previewPopData" :key="index">
                                <iframe id="iframe" :src="carousel.invoicePath" frameborder="0"></iframe>
                            </b-carousel-item>
                        </b-carousel>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
import Pager from '@/components-v2/pager'
import Dialog from '@/components-v2/v2-dialog/index'
import { arrUnique } from '@/utils'
import { AccountStorage } from "@services/account";
const accountStorage = new AccountStorage();
export default {
    name: "electronicInvoice",
    components: {
        DatePicker,
        Pager,
        Dialog,
        electronicInvoiceDetails: () => import('./electronicInvoice-details/electronicInvoice-details')
    },
    data() {
        return {
            act_tab: 0,
            search_val: "",
            time: null,
            startTime: "",
            endTime: "",
            r_icon: "https://obs.pujian.com/frontend/app/user/r_suc.png",
            r_down: "https://obs.pujian.com/frontend/app/user/r_wait.png",
            current: 1, // 页码
            rangeBefore: 3,
            rangeAfter: 1,
            isShowRuler: false,
            isShowDetails: false,
            isShowEmail:false,
            isShowMsg:false,
            customerMail:"",
            infoList: [],
            detailData: {},
            pageInfo: {
                total: 0,
                pageSize: 10,
                pageNum: 1
            },
            checkList:[],//选择的集合
            msgData:{},//uuid集合
            invoiceIds:[],
            btnType:"",
            checkOrder:0,//选中的订单
            checkMoney:0,//选中的金额
            checkInvoice:0,//选中的发票数量
            allChecked:false,//批量操作
            modeTabList:["本店发票","连锁店发票"],
            act_index:0,
            act_str:"本店发票",
            chainData:{},//连锁数据
            storeCurrent:[],//门店选择的结果
            salesmanCurrent: [],//业务员选择的结果
            previewPop: false,
            previewPopData:[],
            actUrl: 0,
            arrow: true,
            arrowBoth: true,
            arrowHover: false,
            popupInvoicePrompt: '电子发票', // 弹窗发票提示
            billCountValue: ''
        }
    },
    created() {
        this.getIschain();
        if (this.$route.params.orderNumber) {
            this.toDeatils(this.$route.params)
        }
    },
    methods: {
        //预览
        previewInvoice(data, val) {
            this.previewPopData = [];
            data.forEach(item => {
              if (item.invoiceList) {
                item.invoiceList.forEach(t => {
                  if (t.invoicePath) this.previewPopData.push(t)
                })
              }
            })
            if (this.previewPopData.length > 0){
                this.previewPop = true
            }else{
                this.$buefy.toast.open({
                    message: "暂无可预览发票！",
                    type: "is-danger"
                });
            }
        },
        changeUrl(e) {
            this.actUrl = e;
        },
        //获取是否连锁
        async getIschain(){
            const res = await this.$getHttpClient().get("/pjyy-deepexi-user-center/api/v1/company/getChainShopInfo/" + accountStorage.getCacheUserInfo().account);
            if (res.data.code == 200){
                this.chainData = res.data.data;
                if (this.chainData.companyIds) {
                  this.storeCurrent = this.chainData.companyIds.map(item => item.id)
                }
                await this.getInvoiceList()
            }
        },
        //业务员筛选
        async staffIdsChange(e) {
          this.salesmanCurrent = e;
          let arr = this.chainData.companyIds.filter(item => this.salesmanCurrent.includes(item.staffId))
          this.storeCurrent = arr.map(item => item.id)
          this.getInvoiceList();
        },
        //门店筛选
        async companyIdsChange(e){
            this.storeCurrent = e;
            await this.getInvoiceList();
        },
        //模式tab切换
        tabChange(str,index){
            if (index === 0){
                this.salesmanCurrent = "";
                this.storeCurrent = this.chainData.companyIds.map(item => item.id);
            }
            this.act_str = str;
            this.act_index = index;
            this.getInvoiceList();
            this.$forceUpdate()
        },
        //切换Tab
        checkTab(val) {
            this.current = 1;
            this.act_tab = val;
            this.r_icon = val === 0 ? "https://obs.pujian.com/frontend/app/user/r_suc.png" : "https://obs.pujian.com/frontend/app/user/r_wait.png"
            if (this.chainData.companyIds) {
              this.storeCurrent = this.chainData.companyIds.map(item => item.id);
            }
            this.getInvoiceList();
        },
        clearIconClick() {
            this.current = 1;
            this.search_val = "";
            this.getInvoiceList();
        },
        //店铺单选
        userCheckboxChange(e,item,index,temp,tindex){
            this.infoList[index].orderInvoiceShopVOList[tindex].checked = e;
            if (e){
                this.checkOrder += temp.orderCount;
                this.checkMoney += temp.orderAmountCount;
                this.checkInvoice += temp.billCount;
                this.checkList.push(temp);
                let num = 0;
                for (let i in this.infoList[index].orderInvoiceShopVOList){
                    if (this.infoList[index].orderInvoiceShopVOList[i].checked){
                        num += 1
                    }
                }
                if (num === this.infoList[index].orderInvoiceShopVOList.length){
                    this.infoList[index].checked = true
                }
                let num1 = 0;
                for (let i in this.infoList){
                    if (this.infoList[i].checked){
                        num1 += 1
                    }
                }
                if(this.infoList.length === num1){
                    this.allChecked = true;
                }
            }else{
                this.allChecked = false;
                this.infoList[index].checked = false;
                this.checkOrder -= temp.orderCount;
                this.checkMoney -= temp.orderAmountCount;
                this.checkInvoice -= temp.billCount;
                for (let i in this.checkList){
                    if (this.checkList[i].companyName == temp.companyName && this.checkList[i].month == temp.month){
                        this.checkList.splice(i,1)
                    }
                }
            }
            this.getSelectedOrderInvoice()
        },
        //月份单选
        monthCheckboxChange(e,item,index){
            this.infoList[index].checked = e;
            if (e){
                for (let i in item.orderInvoiceShopVOList){
                    if (!item.orderInvoiceShopVOList[i].checked){
                        item.orderInvoiceShopVOList[i].checked = true;
                        this.checkOrder += item.orderInvoiceShopVOList[i].orderCount;
                        this.checkMoney += item.orderInvoiceShopVOList[i].orderAmountCount;
                        this.checkInvoice += item.orderInvoiceShopVOList[i].billCount;
                        this.checkList.push(item.orderInvoiceShopVOList[i])
                    }
                }
                let num = 0;
                for (let i in this.infoList){
                    if (this.infoList[i].checked){
                        num += 1
                    }
                }
                for (let i in this.infoList[index].orderInvoiceShopVOList){
                    this.infoList[index].orderInvoiceShopVOList[i].checked = true
                }
                if(this.infoList.length === num){
                    this.allChecked = true;
                }
            }else{
                this.allChecked = false;
                for (let i in this.infoList[index].orderInvoiceShopVOList){
                    if(this.infoList[index].orderInvoiceShopVOList[i].month == item.month){
                        this.checkOrder -= this.infoList[index].orderInvoiceShopVOList[i].orderCount;
                        this.checkMoney -= this.infoList[index].orderInvoiceShopVOList[i].orderAmountCount;
                        this.checkInvoice -= this.infoList[index].orderInvoiceShopVOList[i].billCount;
                        this.checkList = this.checkList.filter(res => res.month != item.month)
                    }
                }
                for(let i in this.infoList[index].orderInvoiceShopVOList){
                    this.infoList[index].orderInvoiceShopVOList[i].checked = false
                }
            }
            this.getSelectedOrderInvoice()
        },
        //全选
        allOrderChange(e){
            this.checkList = [];
            this.checkOrder = 0;
            this.checkMoney = 0;
            this.checkInvoice = 0;
            this.allChecked = e;
            let list = this.infoList;
            if (this.allChecked){
                for (let i in list){
                    for (let j in list[i].orderInvoiceShopVOList) {
                        this.checkOrder += list[i].orderInvoiceShopVOList[j].orderCount;
                        this.checkMoney += list[i].orderInvoiceShopVOList[j].orderAmountCount;
                        this.checkInvoice += list[i].orderInvoiceShopVOList[j].billCount;
                        list[i].checked = true;
                        list[i].orderInvoiceShopVOList[j].checked = true;
                        this.checkList.push(list[i].orderInvoiceShopVOList[j])
                    }
                }
            }else{
                for (let i in list){
                    for (let j in list[i].orderInvoiceShopVOList) {
                        list[i].checked = false;
                        list[i].orderInvoiceShopVOList[j].checked = false;
                    }
                }
            }
            this.getSelectedOrderInvoice()
        },
        // 获取选中的发票数据
        getSelectedOrderInvoice() {
          let oldInvoiceList = []
          this.infoList.forEach(item => {
            if (item.checked) {
              oldInvoiceList = [...oldInvoiceList, ...item.orderInvoiceShopVOList[0].invoiceList]
            }
          })
          let newInvoiceList = arrUnique(oldInvoiceList, 'invoiceType')
          let str = '';
          newInvoiceList.forEach(item => {
            if (item.invoiceType == 51) str += '电子普票、';
            if (item.invoiceType == 52) str += '数电普票、';
            if (item.invoiceType == 53) str += '数电专票、';
          })
          this.popupInvoicePrompt = str.slice(0, -1);
        },
        //保存邮箱
        async saveEmail() {
            const res = await this.$getHttpClient().post(
                    "/pjyy-deepexi-order-center/api/v1/order/OcOrderEInvoice/saveEmail", {
                        email: this.customerMail
                    }
            );
            if (res.data.code == 200) {

            }
        },
        //获取邮箱
        async getEmail() {
            const res = await this.$getHttpClient().get("/pjyy-deepexi-order-center/api/v1/order/OcOrderEInvoice/getEmail");
            if (res.data.code == 200) {
                this.customerMail = res.data.msg
            }
        },
        //错误提示确定按钮
        async knowBtn(){
            this.isShowMsg = false;
            let arr = this.checkList.filter(item => {
                return item.invoiceId.length > 0
            })
            if(arr.length <= 0){
                this.$buefy.toast.open({
                    message: "没有可分享或者下载的电子发票",
                    type: "is-danger"
                });
                return
            }
            if(this.btnType == "share"){
                const res = await this.$getHttpClient().post(
                        "/pjyy-deepexi-order-center/api/v1/order/OcOrderEInvoice/orderInvoiceBatchSharing", {
                            dto: arr
                        }
                );
                if (res.data.code == 200) {
                    this.msgData = res.data.data;
                    this.invoiceCopy()
                }
            }else if(this.btnType == "download"){
                this.invoiceDownload()
            }else if(this.btnType == "email"){
              this.getEmail();
              this.isShowEmail = true
            }
        },
        //邮箱验证
        checkEmail(email) {
            return RegExp(/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/).test(email);
        },
        //发送邮箱
        async subEmailShare() {
            if (!this.checkEmail(this.customerMail)) {
                this.$buefy.toast.open({
                    message: "请填写正确的邮箱地址",
                    type: "is-danger"
                });
                return false;
            }
            const res = await this.$getHttpClient().post(
                    "/pjyy-deepexi-order-center/api/v1/order/OcOrderEInvoice/sendEmail", {
                        customerMail: this.customerMail,
                        invoiceIds: this.invoiceIds
                    }
            );
            if (res.data.code == 200) {
                this.$buefy.toast.open({
                    message: "分享成功",
                    type: "is-success"
                });
                this.saveEmail()
            }
            this.isShowEmail = false
        },
        //下载发票
        async invoiceDownload() {
            let str = this.invoiceIds.join(",");
            const res = await this.$getHttpClient().get(
                    "/pjyy-deepexi-order-center/api/v1/order/OcOrderEInvoice/downInvoice?invoiceIds=" + str
            );
            if (res.data.code == 200) {
                window.open(res.data.msg, '_blank');
            }
        },
        //复制链接
        invoiceCopy() {
            var str = this.msgData.uuid;
            const ENV_APP = process.env.VUE_APP_API_SERVER;
            let openUrl = "";
            if (ENV_APP == "https://api-uat.pujian.com") {
                openUrl = "https://pay-uat.pujian.com/#/invoiceList?uuid=" + str
            } else if (ENV_APP == "https://api-pre.pujian.com") {
                openUrl = "https://pay-pre.pujian.com/#/invoiceList?uuid=" + str
            } else if (ENV_APP == "https://api.pujian.com") {
                openUrl = "https://pay.pujian.com/#/invoiceList?uuid=" + str
            }
            // 模拟 输入框
            var cInput = document.createElement("textarea");
            cInput.value = "【普健医药】发票预览\r\n" + openUrl;
            document.body.appendChild(cInput);
            cInput.select(); // 选取文本框内容
            // 执行浏览器复制命令
            // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
            // Input要在正常的编辑状态下原生复制方法才会生效
            document.execCommand("copy");
            // 复制成功后再将构造的标签 移除
            document.body.removeChild(cInput);
            this.$buefy.toast.open({
                message: "发票链接复制成功",
                type: "is-success"
            });
        },
        //查询uuid
        async getShareUuid(type){
            if (this.checkList.length === 0){
                this.$buefy.toast.open({
                    message: "请选择发票！",
                    type: "is-danger"
                });
                return ;
            }
            this.invoiceIds = [];
            for (let i in this.checkList){
                this.invoiceIds = this.invoiceIds.concat(this.checkList[i].invoiceId)
            }
            this.btnType = type;
            this.isShowMsg = true;
        },
        //返回发票中心
        backInvoice(flag) {
            this.isShowDetails = false
        },
        //设置选择今天以及今天之前的日期
        disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6;
        },
        // 日期下拉框
        handleSelect(val) {
            this.current = 1;
            this.startTime = val[0];
            this.endTime = val[1];
            console.log(this.startTime, this.endTime);
            this.getInvoiceList()
        },
        //清空时间
        handleClear() {
            this.current = 1;
            this.startTime = "";
            this.endTime = "";
            this.getInvoiceList()
        },
        //日期格式化
        formatDate(value) {
            let date = new Date(value);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "/" + MM + "/" + d;
        },
        //分页
        handleChange(val) {
            this.current = val;
            this.getInvoiceList()
        },
        toDeatils(item) {
            this.getInvoiceDetail(item.orderNumber)
        },
        //获取发票详情
        async getInvoiceDetail(orderNumber) {
            const res = await this.$getHttpClient().get(
                "/pjyy-deepexi-order-center/api/v1/order/OcOrderEInvoice/orderInvoiceDetail?orderNumber=" + orderNumber);
            if (res.data.code == 200) {
                this.detailData = res.data.data;
                this.isShowDetails = true;
            }
        },
        //展开收起
        readMore(flag,index){
            if(flag){
                this.infoList[index].isShowMore = false;
                this.infoList[index].readMoreTxt = "查看更多";
            }else{
                this.infoList[index].isShowMore = true;
                this.infoList[index].readMoreTxt = "收起";
            }
            this.$forceUpdate()
        },
        //获取发票列表
        async getInvoiceList() {
            if(this.search_val){
                this.current=1;
            }
            this.infoList = [];
            const res = await this.$getHttpClient().post(
                "/pjyy-deepexi-order-center/api/v1/order/OcOrderEInvoice/orderInvoiceList", {
                invoiceStatus: this.act_tab + 1,
                orderNumber: this.search_val,
                page: this.current,
                pageSize: this.perPage,
                startTime: this.startTime,
                endTime: this.endTime,
                isChain:this.act_index,
                accounts:this.storeCurrent,//店铺id
            }
            );
            if (res.data.code == 200) {
                this.infoList = res.data.data;
                for (let i in this.infoList){
                    this.infoList[i].checked = false;
                    this.infoList[i].readMoreTxt = "查看更多";
                    this.infoList[i].isShowMore = false;
                    this.infoList[i].id = i;
                    this.infoList[i].newMonth = this.infoList[i].month.replace(/-/g, "年") + "月"
                }
            }
        },
    }
}
</script>

<style src="./electronicInvoice.less" scoped lang="less">

</style>
